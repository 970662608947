/* standardFlow Name */
/* External Imports */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import { max } from "d3";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

/* Local Imports */
// constants
import { STYLES, STATUS_KEYS, METRICS } from "@/globals/constants";
import {
  EVENT_DETAIL_EVENTS,
  EVENTS,
  STAGES,
  EVENT_LABELS,
  STAGE_LABELS,
  STAGE_TABS,
  EVENT_MEDICATIONS,
  PROGRESS_UPDATE_DEFAULTS,
  MEDICATION_FORM_DEFAULTS,
  NEWBORN_PROGRESS_UPDATE_DEFAULTS,
  SINCE_RUPTURE_EVENTS,
  FIRST_STAGE_EVENTS,
  SECOND_STAGE_EVENTS,
  THIRD_STAGE_EVENTS,
  SINCE_BIRTH_EVENTS,
  STAGE_TABS_V2,
  EVENT_DETAIL_EVENTS_V2
} from "./constants";

// store
import { RootState } from "@/store/store";
import {
  EncounterInfo,
  IdType,
  LaborEvent,
  LaborSheetEventType,
  useEncounterDeleteMutation,
  useEncounterLaborEventDeleteMutation,
  useEncounterLaborEventUpsertMutation,
  useEncounterLaborInfoQuery,
  useEncounterLaborRecurringEventCreateMutation,
  useEncounterLaborRecurringEventDeleteMutation,
  useEncounterLaborRecurringEventUpdateMutation,
  useEncounterLaborSheetQuery
} from "@/store/services/encounter";
import { useEncounterLaborUpsertMutation } from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import {
  LaborModel,
  LaborSheet,
  PatientType,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { LocationInfo } from "@/store/services/location";

// Components
import OptionsMenu from "@/components/optionsMenu";
import TabMenu from "@/components/tabbed";
import Birthform from "@/components/forms/_labor/birth";
import BloodLossform from "@/components/forms/_labor/bloodLoss";
import Hospitalform from "@/components/forms/_labor/hospital";
import Lacerationform from "@/components/forms/_labor/laceration";
import MaternalProgressform from "@/components/forms/_labor/maternalProgress";
import Placentaform from "@/components/forms/_labor/placenta";
import ROMform from "@/components/forms/_labor/ROM";
import Transportform from "@/components/forms/_labor/transport";
import LaborFlowSheet from "@/components/laborFlowSheet";
import DetailTimeline from "@/components/detailTimeline";
import Timestampform from "@/components/forms/_labor/timestamp";
import BasicAccordion from "@/components/accordions/basic";
import Medicationform from "@/components/forms/_labor/medication";
import ProgressUpdateform from "@/components/forms/_labor/progressUpdate";
import NewbornForm from "@/components/forms/_labor/newbornExam/newborn";
import Noteform from "@/components/forms/_labor/note";
import BirthProgressUpdateform from "@/components/forms/_labor/birthProgressUpdate";
import NewbornProgressform from "@/components/forms/_labor/newbornProgress";
import ApgarForm from "@/components/forms/_labor/apgar";
import Apgar5Form from "@/components/forms/_labor/apgar5";
import Apgar10Form from "@/components/forms/_labor/apgar10";
import FirstLatchform from "@/components/forms/_labor/firstLatch";
import Alert from "@/components/alert";
import Hydrotherapyform from "@/components/forms/_labor/hydrotherapy";
import TransferDecisionForm from "@/components/forms/_labor/transferDecisionForm/transferDecisionForm";
import Button from "@/components/button";
import BirthSummary from "@/components/birthSummary";
import SummaryForm from "@/components/forms/_labor/summaryForm";
import TimerIndex from "@/components/timer";
import MilestoneMarker from "@/components/milestoneMarker";
import DashboardCard from "@/components/cards/dashboardCard";
import AssessmentPlan from "../_sections/assessmentPlan";
import Submission from "../_sections/submission";
import ErrorBoundary from "@/components/errorBoundary";
import MaternalVitalsForm from "@/components/forms/_labor/detailTimelineForms/maternalVitals";
import NewbornVitalsForm from "@/components/forms/_labor/detailTimelineForms/newbornVitals";
import VitalsProgressUpdateform from "@/components/forms/_labor/vitalsProgressUpdate";

// styles
import styles from "./styles.module.scss";

// helpers
import { normalizePhoneNumber } from "@/globals/helpers";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime
} from "@/components/scheduling/calendars/utils";
import { setTabId } from "@/components/tabbed/tabSlice";

/* Well Woman Typescript Interface */
interface standardFlowProps {
  encounter: EncounterInfo;
}

export default function LaborFlow({ encounter }: standardFlowProps) {
  const { provider, patient } = encounter;

  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const searchParams = useSearchParams();

  // labor
  const [upsertPatient] = usePatientUpsertMutation();
  const [upsertLabor] = useEncounterLaborUpsertMutation();
  const [upsertLaborEvent] = useEncounterLaborEventUpsertMutation();
  const [addRecurringEvent] = useEncounterLaborRecurringEventCreateMutation();
  const [updateRecurringEvent] =
    useEncounterLaborRecurringEventUpdateMutation();
  const [deleteRecurringEvent] =
    useEncounterLaborRecurringEventDeleteMutation();
  const [deleteLaborEvent] = useEncounterLaborEventDeleteMutation();

  const [deleteLaborEncounter] = useEncounterDeleteMutation();

  const { data: labor } = useEncounterLaborInfoQuery(
    {
      encounterId: encounter.encounter_id as number
    },
    { skip: !encounter || !encounter.encounter_id }
  );

  // labor flow sheet
  const { data: laborSheet } = useEncounterLaborSheetQuery(
    {
      encounterId: encounter.encounter_id as number
    },
    { skip: !encounter || !encounter.encounter_id }
  );

  /* Local State */
  const stageTabs = useMemo(() => {
    if (labor?.stages.birth) {
      return STAGE_TABS;
    } else {
      return STAGE_TABS_V2;
    }
  }, [labor]);

  const eventDetailEvents = useMemo(() => {
    if (labor?.stages.birth) {
      return EVENT_DETAIL_EVENTS;
    } else {
      return EVENT_DETAIL_EVENTS_V2;
    }
  }, [labor]);

  const [selectedStage, setSelectedStage] = useState(STAGES.ADMISSION);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [openSheet, setOpenSheet] = useState<null | string>(null);
  const [selectedTimelineMedication, setSelectedTimelineMedication] = useState<
    string | null
  >(null);
  const [showBirthSummary, setShowBirthSummary] = useState<boolean>(false);
  const [showCodingBilling, setShowCodingBilling] = useState<boolean>(false);

  const [timersStopped, setTimersStopped] = useState<boolean>(false);

  const [listeningDevice, setListeningDevice] = useState<string>("doppler");

  const [lastClickedTimelineEvent, setLastClickedTimelineEvent] =
    useState<string>("");

  const eventMilestones = eventDetailEvents[selectedStage];

  const router = useRouter();

  /* Effects */
  const lastBaselineEventId = useMemo(() => {
    // Sort all progres updates based on time
    let sortedProgressUpdates = [
      ...(labor?.stages?.labor?.progress_updates || [])
    ].sort((a, b) => (b?.start || 0) - (a?.start || 0));
    // Find the latest progress update with a baseline value
    const lastBaselineEvent = sortedProgressUpdates.find(
      event => event.forms && (event.forms as Record<string, any>).new_baseline
    );

    if (lastBaselineEvent) {
      // If we have an event it's safe to cast it as Record<string, any>
      return lastBaselineEvent.event_id;
    }
    return "";
  }, [labor]);

  useEffect(() => {
    const _showBirthSummary = searchParams?.get("showBirthSummary");
    const _showCodingBilling = searchParams?.get("codingBilling");

    setShowBirthSummary(!!_showBirthSummary);
    setShowCodingBilling(!!_showCodingBilling);
  }, [searchParams]);

  useEffect(() => {
    // Start/stop timers
    if (
      labor &&
      labor.stages[STAGES.CONCLUSION] &&
      labor.stages[STAGES.CONCLUSION].events &&
      labor.stages[STAGES.CONCLUSION].events[EVENTS.STOP_TIMERS]
    ) {
      setTimersStopped(true);
    } else {
      setTimersStopped(false);
    }
  }, [labor]);

  /* Event Handlers */

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  const handleSelectEvent = (event: string) => {
    setSelectedStage(event);
    if (event == STAGES.TRANSFER) {
      dispatch(setTabId("transfer"));
    }
    if (event == EVENTS.NEWBORN_EXAM) {
      dispatch(setTabId("newborn_exam"));
    }
    if (event === STAGES.CONCLUSION) {
      const milestone = EVENTS.LABOR_CONCLUDED;
      const now = convertLocalDatetimeToUtcInt(dayjs());
      const laborEventUpdate = {
        event_id: milestone as string,
        id: milestone as string,
        start: now as number
      };
      if (labor) {
        // only record start time if it does not currently exist
        if (!labor?.stages[STAGES.CONCLUSION]?.events?.[milestone]?.start) {
          const payload = {
            encounterId: encounter.encounter_id as number,
            stage: STAGES.CONCLUSION,
            laborEvent: milestone as string, // will always be a string by the time we get here
            /** Payload for creating/updating a labor.
             */
            laborEventUpdate
          };

          upsertLaborEvent(payload);
        }
      }
    }
    setSelectedEvent(null); // clear event selection when stage changes
  };

  const handleMilestoneClick = (milestone: string) => {
    setSelectedEvent(milestone);
    // if event is being initialized, create timestamp
    if (!labor?.stages[selectedStage]?.events?.[milestone]) {
      const now = convertLocalDatetimeToUtcInt(dayjs());

      const laborEventUpdate = {
        event_id: milestone as string,
        id: milestone as string,
        start: now
      };

      if (patient.pregnancy) {
        const { pregnancy } = patient;
        if (labor) {
          // only record start time if it does not currently exist
          if (!labor?.stages[selectedStage]?.events?.[milestone]?.start) {
            const payload = {
              encounterId: encounter.encounter_id,
              stage: selectedStage,
              laborEvent: milestone as string, // will always be a string by the time we get here
              /** Payload for creating/updating a labor.
               */
              laborEventUpdate
            };

            upsertLaborEvent(payload)
              .unwrap()
              .then(res => {
                launchToast(
                  `${EVENT_LABELS[milestone]} timestamp recorded`,
                  STATUS_KEYS.SUCCESS
                );
                if (res.patient_id) {
                  launchToast(`Newborn chart created`, STATUS_KEYS.SUCCESS);
                }
                setOpenSheet(res.stage);
              })
              .catch(() =>
                launchToast(
                  `Oops! Labor could not be updated`,
                  STATUS_KEYS.ERROR
                )
              );
          }
        } else {
          const payload = {
            encounterId: encounter.encounter_id,
            laborModel: {
              pregnancy_id: pregnancy.pregnancy_id,
              stages: {
                [selectedStage]: {
                  events: { [milestone]: laborEventUpdate },
                  progress_updates: [],
                  medications: [],
                  notes: []
                }
              }
            }
          };
          // create labor
          upsertLabor(payload)
            .unwrap()
            .then(() => {
              launchToast(`Labor successfully created`, STATUS_KEYS.SUCCESS);
            })
            .catch(err =>
              launchToast(`Oops! Labor could not be created`, STATUS_KEYS.ERROR)
            );
        }
      }
    }
  };

  const handleSubmit = (formData: any, event: string) => {
    const laborEventUpdate = {
      event_id: event,
      id: event,
      forms: formData,
      start: parseInt(formData.start_time)
    };

    // if labor already exists for this pregnancy,
    // update this specific event
    if (patient && patient.pregnancy && labor) {
      // should start timestamp be handled on backend?
      const { pregnancy } = patient;

      // Overwrite the global state specifically for the newborn exam
      let _selectedStage = selectedStage;

      if (event == EVENTS.NEWBORN_EXAM) {
        _selectedStage = STAGES.POSTPARTUM;
      }

      upsertLaborEvent({
        encounterId: encounter.encounter_id,
        stage: _selectedStage,
        laborEvent: event, // will always be a string by the time we get here
        /** Payload for creating/updating a labor.
         */
        laborEventUpdate
      })
        .unwrap()
        .then(res => {
          launchToast(
            `${
              (event && EVENT_LABELS[event]) || STAGE_LABELS[_selectedStage]
            } successfully updated`,
            STATUS_KEYS.SUCCESS
          );
          setOpenSheet(res.stage);
        })
        .catch(err =>
          launchToast(
            `Oops! ${event && EVENT_LABELS[event]} could not be updated`,
            STATUS_KEYS.ERROR
          )
        );
    } else if (patient.pregnancy) {
      const { pregnancy } = patient;
      // create labor for the first time
      // populate with single event
      const payload = {
        encounterId: encounter.encounter_id,
        laborModel: {
          pregnancy_id: pregnancy.pregnancy_id,
          stages: {
            [selectedStage]: {
              events: {
                [event]: laborEventUpdate
              },
              progress_updates: [],
              medications: [],
              notes: []
            }
          }
        }
      };
      // create labor
      upsertLabor(payload)
        .unwrap()
        .then(() => {
          launchToast(`Labor successfully created`, STATUS_KEYS.SUCCESS);
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be created`, STATUS_KEYS.ERROR)
        );
    }
  };

  const handleAddRecurringEvent = (formData: any, scope: IdType) => {
    const newFormData = {
      ...formData,
      start_time: formData.start_time
    };
    const laborEventCreate = {
      forms: newFormData,
      start: parseInt(formData.start_time)
    };
    if (patient && patient.pregnancy && labor) {
      // should start timestamp be handled on backend?
      const { pregnancy } = patient;

      const payload = {
        encounterId: encounter.encounter_id,
        stage: selectedStage,
        scope,
        /** Payload for creating/updating a labor.
         */
        laborEventCreate
      };
      addRecurringEvent(payload)
        .unwrap()
        .then(res => {
          launchToast(
            `${scope.replace("_", " ")} recorded`,
            STATUS_KEYS.SUCCESS
          );
          setOpenSheet(res.stage);
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };

  const handleUpdateRecurringEvent = (
    formData: any,
    laborEventId: string,
    scope: IdType
  ) => {
    const newFormData = {
      ...formData,
      start_time: formData.start_time
    };
    const laborEventUpdate = {
      event_id: laborEventId,
      forms: newFormData,
      start: parseInt(formData.start_time)
    };

    if (patient && patient.pregnancy && labor) {
      // should start timestamp be handled on backend?
      const { pregnancy } = patient;

      const payload = {
        encounterId: encounter.encounter_id,
        stage: selectedStage,
        laborEventId,
        scope,
        /** Payload for creating/updating a labor.
         */
        laborEventUpdate
      };
      updateRecurringEvent(payload)
        .unwrap()
        .then(res => {
          launchToast(`Labor successfully updated`, STATUS_KEYS.SUCCESS);
          setOpenSheet(res.stage);
          setLastClickedTimelineEvent(laborEventId);
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };

  // handler when event is clicked on timeline
  const handleEventClick = (event: string) => {
    const now = convertLocalDatetimeToUtcInt(dayjs());
    const laborEventUpdate = {
      id: event as string,
      event_id: event as string,
      start: now,
      authors: [sessionInfo?.user_id as number],
      forms: { name: event }
    };

    // if labor already exists for this pregnancy,
    // update this specific event
    if (patient && patient.pregnancy && labor) {
      // should start timestamp be handled on backend?
      const { pregnancy } = patient;

      const payload = {
        encounterId: encounter.encounter_id,
        stage: selectedStage,
        event: event as string, // will always be a string by the time we get here
        /** Payload for creating/updating a labor.
         */
        laborEventUpdate
      };

      let scope = "recurring_event";
      if (EVENT_MEDICATIONS.includes(event)) {
        scope = "medication";
      }

      addRecurringEvent({
        ...payload,
        scope: scope as IdType,
        laborEventCreate: laborEventUpdate
      })
        .unwrap()
        .then(res => {
          launchToast(`${event} successfully updated`, STATUS_KEYS.SUCCESS);
          setOpenSheet(res.stage);
          setLastClickedTimelineEvent(res.labor_event_id || "");
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };

  const getProviderPhone = () => {
    const phone = normalizePhoneNumber(encounter.provider.phone || "");
    if (phone.error && phone.error !== "") {
      return "";
    } else {
      return phone.normalizedPhoneNumber;
    }
  };

  const handleTimerStop = () => {
    const { pregnancy } = patient;
    const milestone = EVENTS.STOP_TIMERS;
    const now = convertLocalDatetimeToUtcInt(dayjs());
    const laborEventUpdate = {
      event_id: milestone as string,
      id: milestone as string,
      start: now as number
    };
    if (labor) {
      // only record start time if it does not currently exist
      if (!labor?.stages[STAGES.CONCLUSION]?.events?.[milestone]?.start) {
        const payload = {
          encounterId: encounter.encounter_id as number,
          stage: STAGES.CONCLUSION,
          laborEvent: milestone as string, // will always be a string by the time we get here
          /** Payload for creating/updating a labor.
           */
          laborEventUpdate
        };

        upsertLaborEvent(payload)
          .unwrap()
          .then(res => {
            launchToast(`Timers successfully stopped`, STATUS_KEYS.SUCCESS);
          })
          .catch(() =>
            launchToast(`Oops! Timers could not be stopped`, STATUS_KEYS.ERROR)
          );
      }
    }
  };

  const handleUndoTimerStop = () => {
    handleRemoveEvent(EVENTS.STOP_TIMERS, STAGES.CONCLUSION);
  };

  // Render Form
  const handleRenderForm = (event: string) => {
    switch (event) {
      case EVENTS.BIRTH:
        return (
          <Birthform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.BIRTH]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.BIRTH]
                ?.start as number
            }
          />
        );
      case EVENTS.BLOOD_LOSS:
        return (
          <BloodLossform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.BLOOD_LOSS]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.BLOOD_LOSS]
                ?.start as number
            }
          />
        );
      case EVENTS.HOSPITAL_NOT:
        return (
          <Hospitalform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.HOSPITAL_NOT]?.forms
            }
          />
        );
      case EVENTS.LACERATION:
        return (
          <Lacerationform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.LACERATION]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.LACERATION]
                ?.start as number
            }
          />
        );
      case EVENTS.PROGRESS_UPDATE:
        return (
          <MaternalProgressform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.HOSPITAL_NOT]?.forms
            }
          />
        );
      case EVENTS.PLACENTA:
        return (
          <Placentaform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.PLACENTA]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.PLACENTA]
                ?.start as number
            }
          />
        );
      case EVENTS.ROM:
        return (
          <ROMform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: labor?.stages[selectedStage]?.events?.[EVENTS.ROM]
                ?.start as number,
              ...labor?.stages[selectedStage]?.events?.[EVENTS.ROM]?.forms
            }}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.ROM]
                ?.start as number
            }
            endTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.ROM]?.end as number
            }
          />
        );
      case EVENTS.NEWBORN_EXAM:
        return (
          <NewbornForm
            // Hardcoding event because newborn exam isn't with the other events
            onSubmit={v => handleSubmit(v, EVENTS.NEWBORN_EXAM)}
            patientId={patient.user_id}
            disabled={timersStopped}
            encounterId={encounter.encounter_id}
            examType="LABOR"
          />
        );
      case EVENTS.TRANSPORT:
        return <Transportform onSubmit={v => handleSubmit(v, event)} />;
      case EVENTS.APGAR_1:
        return (
          <ApgarForm
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_1]
                ?.start as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_1]?.forms
            }
            apgarMin={1}
          />
        );
      case EVENTS.APGAR_5:
        return (
          <Apgar5Form
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_5]
                ?.start as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_5]?.forms
            }
            apgarMin={5}
          />
        );
      case EVENTS.APGAR_10:
        return (
          <Apgar10Form
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_10]
                ?.start as number
            }
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_10]?.forms
            }
            apgarMin={10}
          />
        );
      case EVENTS.LATCH:
        return (
          <FirstLatchform
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.LATCH]
                ?.start as number
            }
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.LATCH]?.forms
            }
          />
        );
      case EVENTS.MATERNAL_VITALS:
        return (
          <MaternalVitalsForm
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
              ...labor?.stages[selectedStage]?.events?.[EVENTS.PARENT_DISCHARGE]
                ?.forms
            }}
          />
        );
      case EVENTS.NEWBORN_VITALS:
        return (
          <NewbornVitalsForm
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
          />
        );
      default:
        return (
          <Timestampform
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[selectedEvent as string]
                ?.start as number
            }
            endTime={
              labor?.stages[selectedStage]?.events?.[selectedEvent as string]
                ?.end as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[selectedEvent as string]
                ?.forms || { comments: "" }
            }
          />
        );
    }
  };

  const handleDeleteClick = (
    id: string,
    type: LaborSheetEventType,
    stage: string
  ) => {
    const handleDelete = () => {
      if (type === "EVENT") {
        handleRemoveEvent(id, stage);
      }
      if (type === "PROGRESS_UPDATE") {
        handleRemoveRecurringEvent(id, stage, "progress_update");
      }
      if (type === "MEDICATION") {
        handleRemoveRecurringEvent(id, stage, "medication");
      }
      if (type === "INFANT_PROGRESS_UPDATE") {
        handleRemoveRecurringEvent(id, stage, "infant_progress_update");
      }
      if (type === "INFANT_MEDICATION") {
        handleRemoveRecurringEvent(id, stage, "infant_medication");
      }
      if (type === "RECURRING_EVENT") {
        handleRemoveRecurringEvent(id, stage, "recurring_event");
      }
      if (type === "NOTES") {
        handleRemoveRecurringEvent(id, stage, "note");
      }
      dispatch(setModalIsOpen(false));
    };

    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.CONFIRM,
        props: {
          title: "Confirm Deletion",
          message:
            "Are you sure you want to delete " +
            EVENT_LABELS[id] +
            " from the " +
            STAGE_LABELS[stage] +
            " flowsheet?",
          onConfirm: handleDelete
        }
      })
    );
  };

  const handleRemoveEvent = (event: string, stage: string) => {
    if (patient && patient.pregnancy) {
      const { pregnancy } = patient;

      const payload = {
        encounterId: encounter.encounter_id,
        stage: stage as string,
        laborEvent: event as string
      };
      deleteLaborEvent(payload)
        .unwrap()
        .then(res => {
          launchToast(
            `${EVENT_LABELS[event]} successfully deleted`,
            STATUS_KEYS.SUCCESS
          );
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };

  const handleRemoveRecurringEvent = (
    id: string,
    stage: string,
    scope: IdType
  ) => {
    if (patient && patient.pregnancy) {
      const { pregnancy } = patient;

      const payload = {
        encounterId: encounter.encounter_id,
        stage: stage as string,
        scope,
        laborEventId: id as string
      };

      deleteRecurringEvent(payload)
        .unwrap()
        .then(res => {
          launchToast(
            `${scope.replace("_", " ")}
          succcessfully deleted`,
            STATUS_KEYS.SUCCESS
          );
        })
        .catch(err =>
          launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
        );
    }
  };

  const getLastVitals = () => {
    if (labor?.stages) {
      // Add up all of the start values of the global timer stop events
      // and if it's greater than 0 (any of the events have happened)
      // return nothing.
      const timersStopped =
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.STOP_TIMERS]
          ?.start || 0) +
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.LABOR_CONCLUDED]
          ?.start || 0);

      if (timersStopped > 0) {
        return;
      }

      // Filter out triage events from starting the timers
      const filteredStages = Object.fromEntries(
        Object.entries(labor.stages).filter(([k]) => k != STAGES.TRIAGE)
      );

      let progressUpdates = Object.values(filteredStages).flatMap(
        stage => stage.progress_updates
      );

      progressUpdates = progressUpdates.filter(event => {
        if (!event) {
          return false;
        }
        const form = event.forms as Record<string, any>;
        if (!form) {
          return false;
        }

        return form.BP || form.heart_rate || form.temp || form.inputs || form.outputs || form.respirations
      })
      
      let timestamps = progressUpdates.flatMap(p => p  && p.start);
      const nums = timestamps.filter(t => typeof t === "number");
      if (nums) {
        // @ts-ignore
        return max(nums);
      }
    }
  };

  const getLastFHT = () => {
    if (labor?.stages) {
      const timersStopped =
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.STOP_TIMERS]
          ?.start || 0) +
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.LABOR_CONCLUDED]
          ?.start || 0);

      if (timersStopped > 0) {
        return;
      }

      const progressUpdates = Object.values(labor?.stages).flatMap(
        stage => stage.progress_updates
      );
      const timestamps = progressUpdates.flatMap(
        // @ts-ignore
        p => p && p.forms?.FHT && p.start
      );
      const nums = timestamps.filter(t => typeof t === "number");
      if (nums) {
        // @ts-ignore
        return max(nums);
      }
    }
  };

  /**
   *
   * @param tab Optionally pass the tab for stages that have multiple progress forms
   * @returns JSX of a progress form for the stage
   */
  const handleRenderProgressForm = (tab: string = "") => {
    const handleSubmit = (v: any, reset: (values: any) => void) => {
      handleAddRecurringEvent(v, "progress_update");

      if (v.listening_device) {
        setListeningDevice(v.listening_device);
      }

      const defaultOverrides = {
        listening_device: v.listening_device,
        start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
        baseline: lastBaselineEventId
      };

      reset({
        ...PROGRESS_UPDATE_DEFAULTS,
        ...defaultOverrides
      });
    };
    switch (selectedStage) {
      case STAGES.LABOR:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={{
              listening_device: listeningDevice,
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
            lastBaselineEventId={lastBaselineEventId}
            labor={labor as LaborModel}
          />
        );
      case STAGES.HEMORRHAGE:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={{
              listening_device: listeningDevice,
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
            lastBaselineEventId={lastBaselineEventId}
            labor={labor as LaborModel}
          />
        );
      case STAGES.RESUSCITATION:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={{
              listening_device: listeningDevice,
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
            lastBaselineEventId={lastBaselineEventId}
            labor={labor as LaborModel}
          />
        );
      case STAGES.BIRTH:
        return (
          <BirthProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
          />
        );
      case STAGES.POSTPARTUM:
        return (
          <MaternalProgressform
            onSubmit={handleSubmit}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
            key={1}
          />
        );

      case STAGES.TRANSFER:
      case STAGES.ADMISSION:
        return (
          <VitalsProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
          />
        );
      case STAGES.TRIAGE:
        if (tab == "triage") {
          return (
            <VitalsProgressUpdateform
              onSubmit={handleSubmit}
              defaultValues={{
                triage_vitals: "true",
                start_time: dayjs().utc().format("YYYYMMDDHHmmss")
              }}
              disabled={timersStopped}
            />
          );
        }
        return (
          <MaternalProgressform
            onSubmit={handleSubmit}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
            disabled={timersStopped}
            key={1}
          />
        );
      default:
        return <div className="med">No progress update for this stage</div>;
    }
  };

  const handleRenderNewbornProgressForm = () => {
    const handleSubmit = (v: any, reset: (values: any) => void) => {
      handleAddRecurringEvent(v, "infant_progress_update");

      const defaultOverrides = {
        start_time: dayjs().utc().format("YYYYMMDDHHmmss")
      };

      reset({
        ...NEWBORN_PROGRESS_UPDATE_DEFAULTS,
        ...defaultOverrides
      });
    };
    return (
      <NewbornProgressform
        onSubmit={handleSubmit}
        defaultValues={{
          start_time: dayjs().utc().format("YYYYMMDDHHmmss")
        }}
        disabled={timersStopped}
        key={2}
      />
    );
  };

  if (showBirthSummary) {
    return (
      <BirthSummary
        location={patient.location_data as LocationInfo}
        patientId={patient.user_id}
        labor={labor as LaborModel}
        encounter={encounter}
        birthDate={labor?.stages?.birth?.events?.birth?.start}
      />
    );
  }

  if (showCodingBilling) {
    return (
      <div className={styles.CodingBilling}>
        <Button style={STYLES.PRIMARY} onClick={() => router.back()}>
          &lt; Back to labor
        </Button>
        <DashboardCard
          title="Assessment & Plan"
          className={styles.assessmentPlan}
        >
          <AssessmentPlan
            encounter={encounter}
            patient={patient}
            showLabOrdering={false}
          />
        </DashboardCard>
        <Submission
          encounter={encounter}
          onSubmit={() => {
            upsertPatient({
              patientUpsertRequest: {
                user_id: patient.user_id,
                practice_data: {
                  type: "POSTPARTUM"
                }
              }
            });
            const { pregnancy } = patient;
            const milestone = EVENTS.LABOR_CONCLUDED;
            const now = convertLocalDatetimeToUtcInt(dayjs());
            const laborEventUpdate = {
              event_id: milestone as string,
              id: milestone as string,
              start: now as number
            };
            if (labor) {
              // only record start time if it does not currently exist
              if (
                !labor?.stages[STAGES.CONCLUSION]?.events?.[milestone]?.start
              ) {
                const payload = {
                  encounterId: encounter.encounter_id as number,
                  stage: STAGES.CONCLUSION,
                  laborEvent: milestone as string, // will always be a string by the time we get here
                  /** Payload for creating/updating a labor.
                   */
                  laborEventUpdate
                };

                upsertLaborEvent(payload);
              }
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className={clsx(styles.LaborFlow)}>
      <div className={styles.flowWrapper}>
        <div className={styles.right}>
          {!timersStopped ? (
            <Button
              type="button"
              style={STYLES.PRIMARY}
              onClick={() => handleTimerStop()}
            >
              Stop timers
            </Button>
          ) : (
            <Button
              type="button"
              style={STYLES.PRIMARY}
              onClick={() => handleUndoTimerStop()}
            >
              Undo timer stop
            </Button>
          )}
          <Button
            type="button"
            style={STYLES.PRIMARY}
            onClick={() =>
              router.push({
                query: { ...router.query, showBirthSummary: true }
              })
            }
            nativeButtonProps={{ disabled: !labor }}
          >
            Birth Summary
          </Button>
          <Button
            type="button"
            style={STYLES.PRIMARY}
            onClick={() =>
              router.push({ query: { ...router.query, codingBilling: true } })
            }
          >
            Coding & Billing
          </Button>
        </div>
        <div className={styles.birthRecord}>
          <TimerIndex
            events={[
              {
                label: "since rupture",
                event: labor?.stages[SINCE_RUPTURE_EVENTS.START_STAGE]
                  ?.events?.[SINCE_RUPTURE_EVENTS.START_EVENT] as LaborEvent,
                endEvents: [
                  labor?.stages[SINCE_RUPTURE_EVENTS.END_STAGE]?.events?.[
                    SINCE_RUPTURE_EVENTS.END_EVENT
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "first stage",
                event: labor?.stages[FIRST_STAGE_EVENTS.START_STAGE]?.events?.[
                  FIRST_STAGE_EVENTS.START_EVENT
                ] as LaborEvent,
                endEvents: [
                  labor?.stages[FIRST_STAGE_EVENTS.END_STAGE]?.events?.[
                    FIRST_STAGE_EVENTS.END_EVENT
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "second stage",
                event: labor?.stages[SECOND_STAGE_EVENTS.START_STAGE]?.events?.[
                  SECOND_STAGE_EVENTS.START_EVENT
                ] as LaborEvent,
                endEvents: [
                  labor?.stages[SECOND_STAGE_EVENTS.END_STAGE]?.events?.[
                    SECOND_STAGE_EVENTS.END_EVENT
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "third stage",
                event: labor?.stages[THIRD_STAGE_EVENTS.START_STAGE]?.events?.[
                  THIRD_STAGE_EVENTS.START_EVENT
                ] as LaborEvent,
                endEvents: [
                  labor?.stages[THIRD_STAGE_EVENTS.END_STAGE]?.events?.[
                    THIRD_STAGE_EVENTS.END_EVENT
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "since birth",
                event: labor?.stages[SINCE_BIRTH_EVENTS.START_STAGE]?.events?.[
                  SINCE_BIRTH_EVENTS.START_EVENT
                ] as LaborEvent,
                endEvents: [
                  labor?.stages[SINCE_BIRTH_EVENTS.END_STAGE]?.events?.[
                    SINCE_BIRTH_EVENTS.END_EVENT
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "since last FHT",
                event: { start: getLastFHT() } as LaborEvent,
                endEvents: [
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              },
              {
                label: "since last vitals",
                event: { start: getLastVitals() } as LaborEvent,
                endEvents: [
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.STOP_TIMERS
                  ] as LaborEvent,
                  labor?.stages[STAGES.CONCLUSION]?.events?.[
                    EVENTS.LABOR_CONCLUDED
                  ] as LaborEvent
                ]
              }
            ]}
          />
          <div className="flex apart">
            <OptionsMenu
              selectedOption={selectedStage}
              onClick={handleSelectEvent}
              options={
                // Check if the labor has the birth stage, in which case, it's an
                // old labor following the old stage model.
                Object.hasOwn(stageTabs, STAGES.BIRTH)
                  ? [
                      {
                        id: STAGES.ADMISSION,
                        label: STAGE_LABELS[STAGES.ADMISSION]
                      },
                      { id: STAGES.LABOR, label: STAGE_LABELS[STAGES.LABOR] },
                      { id: STAGES.BIRTH, label: STAGE_LABELS[STAGES.BIRTH] },
                      {
                        id: STAGES.POSTPARTUM,
                        label: STAGE_LABELS[STAGES.POSTPARTUM]
                      },
                      {
                        id: STAGES.CONCLUSION,
                        label: STAGE_LABELS[STAGES.CONCLUSION]
                      }
                    ]
                  : [
                      { id: STAGES.TRIAGE, label: STAGE_LABELS[STAGES.TRIAGE] },
                      {
                        id: STAGES.ADMISSION,
                        label: STAGE_LABELS[STAGES.ADMISSION]
                      },
                      { id: STAGES.LABOR, label: STAGE_LABELS[STAGES.LABOR] },
                      {
                        id: STAGES.POSTPARTUM,
                        label: STAGE_LABELS[STAGES.POSTPARTUM]
                      },
                      {
                        id: STAGES.CONCLUSION,
                        label: STAGE_LABELS[STAGES.CONCLUSION]
                      }
                    ]
              }
            />
            <OptionsMenu
              selectedOption={selectedStage}
              onClick={handleSelectEvent}
              options={[
                {
                  id: STAGES.HEMORRHAGE,
                  label: STAGE_LABELS[STAGES.HEMORRHAGE],
                  style: STYLES.DANGER
                },
                {
                  id: STAGES.RESUSCITATION,
                  label: STAGE_LABELS[STAGES.RESUSCITATION],
                  style: STYLES.DANGER
                },
                { id: STAGES.TRANSFER, label: STAGE_LABELS[STAGES.TRANSFER] }
              ]}
            />
          </div>

          <TabMenu
            tabs={stageTabs[selectedStage]}
            style={STYLES.SECONDARY}
            noPadding
          >
            {tab => {
              return (
                <div className={styles.tabContent}>
                  {tab === "soap" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new SOAP note" open>
                        <Noteform
                          onSubmit={(v: any, reset: (values: any) => void) => {
                            handleAddRecurringEvent(v, "note");

                            reset({
                              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
                            });
                          }}
                          disabled={timersStopped}
                          defaultValues={{
                            start_time: dayjs().utc().format("YYYYMMDDHHmmss")
                          }}
                        />
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "medication" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new medication" open>
                        <Medicationform
                          onSubmit={(v: any, reset: (values: any) => void) => {
                            handleAddRecurringEvent(v, "medication");

                            const defaultOverrides = {
                              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
                            };

                            reset({
                              ...MEDICATION_FORM_DEFAULTS,
                              ...defaultOverrides
                            });
                          }}
                          defaultValues={{
                            ...MEDICATION_FORM_DEFAULTS,
                            start_time: dayjs().utc().format("YYYYMMDDHHmmss")
                          }}
                          disabled={timersStopped}
                        />
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "newborn_medication" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new medication" open>
                        <Medicationform
                          onSubmit={v =>
                            handleAddRecurringEvent(v, "infant_medication")
                          }
                          defaultValues={{
                            ...MEDICATION_FORM_DEFAULTS,
                            start_time: dayjs().utc().format("YYYYMMDDHHmmss")
                          }}
                          disabled={timersStopped}
                        />
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "progress" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new progress update" open>
                        {handleRenderProgressForm()}
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "admission" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new admission note" open>
                        {handleRenderProgressForm()}
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "hydrotherapy" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion title="Add new hydrotherapy update" open>
                        <Hydrotherapyform
                          defaultValues={{
                            start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
                            end_time: ""
                          }}
                          onSubmit={(v, reset) => {
                            handleAddRecurringEvent(v, "recurring_event");
                            reset({
                              start_time: dayjs()
                                .utc()
                                .format("YYYYMMDDHHmmss"),
                              end_time: ""
                            });
                          }}
                          disabled={timersStopped}
                        />
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "milestones" && (
                    <>
                      <p className="dark tMd">
                        {STAGE_LABELS[selectedStage]} Milestones
                      </p>
                      <div
                        className={clsx({
                          [styles.timelineWrapper]: [
                            STAGES.HEMORRHAGE,
                            STAGES.RESUSCITATION
                          ].includes(selectedStage),
                          [styles.milestoneWrapper]: ![
                            STAGES.HEMORRHAGE,
                            STAGES.RESUSCITATION
                          ].includes(selectedStage)
                        })}
                        data-cy="event-form-container"
                      >
                        <div
                          className={clsx(styles.milestones, {
                            [styles.vertical]: [
                              STAGES.HEMORRHAGE,
                              STAGES.RESUSCITATION
                            ].includes(selectedStage)
                          })}
                        >
                          {eventMilestones &&
                            eventMilestones.map(milestone => (
                              <>
                                <MilestoneMarker
                                  key={milestone}
                                  label={EVENT_LABELS[milestone]}
                                  id={milestone}
                                  timestamp={
                                    labor?.stages[selectedStage]?.events?.[
                                      milestone
                                    ]?.start
                                  }
                                  highlighted={
                                    milestone === EVENTS.MATERNAL_VITALS ||
                                    milestone === EVENTS.NEWBORN_VITALS
                                  }
                                  isSelected={selectedEvent === milestone}
                                  onClick={() =>
                                    [
                                      STAGES.HEMORRHAGE,
                                      STAGES.RESUSCITATION
                                    ].includes(selectedStage)
                                      ? handleEventClick(milestone)
                                      : handleMilestoneClick(milestone)
                                  }
                                  disabled={timersStopped}
                                />
                              </>
                            ))}
                        </div>
                        {[STAGES.HEMORRHAGE, STAGES.RESUSCITATION].includes(
                          selectedStage
                        ) ? (
                          <>
                            <DetailTimeline
                              stage={selectedStage as keyof typeof STAGES}
                              patient={patient}
                              laborSheet={laborSheet && laborSheet}
                              disabled={timersStopped}
                              lastClickedEventId={lastClickedTimelineEvent}
                              onFormSubmit={handleUpdateRecurringEvent}
                              onDelete={(event, type, stage) =>
                                handleDeleteClick(event, type, stage)
                              }
                            />
                          </>
                        ) : (
                          selectedEvent && handleRenderForm(selectedEvent)
                        )}
                      </div>
                    </>
                  )}
                  {tab === "newborn_progress" && (
                    <div className={styles.flowWrapper}>
                      <BasicAccordion
                        title="Add new infant progress update"
                        open
                      >
                        {handleRenderNewbornProgressForm()}
                      </BasicAccordion>
                    </div>
                  )}
                  {tab === "newborn_exam" && (
                    <div className={styles.flowWrapper}>
                      {handleRenderForm(EVENTS.NEWBORN_EXAM)}
                    </div>
                  )}
                  {tab === "transfer" && (
                    <div className={styles.milestoneWrapper}>
                      <ErrorBoundary isComponentScoped>
                        <TransferDecisionForm
                          encounterId={encounter.encounter_id}
                          patient={patient}
                          defaultValues={{
                            provider_phone: getProviderPhone(),
                            provider_name: FORMAT.name(encounter.provider),
                            decision_to_transfer: dayjs().utc().format("YYYYMMDDHHmmss"),
                            ...labor?.stages[selectedStage]?.events?.[
                              EVENTS.DECISION
                            ]?.forms,
                          }}
                        />
                      </ErrorBoundary>
                    </div>
                  )}
                  {tab === "conclude" && (
                    <div className={styles.milestoneWrapper}>
                      <SummaryForm
                        patient={patient}
                        defaultValues={
                          labor?.stages[selectedStage]?.events?.[EVENTS.SUMMARY]
                            ?.forms
                        }
                        onSubmit={v => handleSubmit(v, EVENTS.SUMMARY)}
                        labor={labor as LaborModel}
                        encounterId={encounter.encounter_id}
                      />
                    </div>
                  )}
                  {tab === "parent_discharge" && (
                    <div className={styles.milestoneWrapper}>
                      <MaternalProgressform
                        onSubmit={v => handleSubmit(v, EVENTS.PARENT_DISCHARGE)}
                        defaultValues={{
                          start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
                          ...labor?.stages[selectedStage]?.events?.[
                            EVENTS.PARENT_DISCHARGE
                          ]?.forms
                        }}
                      />
                    </div>
                  )}
                  {tab === "newborn_discharge" && (
                    <div className={styles.milestoneWrapper}>
                      <NewbornProgressform
                        onSubmit={v =>
                          handleSubmit(v, EVENTS.NEWBORN_DISCHARGE)
                        }
                        defaultValues={{
                          start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
                          ...labor?.stages[selectedStage]?.events?.[
                            EVENTS.NEWBORN_DISCHARGE
                          ]?.forms
                        }}
                        disabled={timersStopped}
                      />
                    </div>
                  )}
                  {tab === "triage" && (
                    <BasicAccordion title="Add triage details" open>
                      {handleRenderProgressForm(tab)}
                    </BasicAccordion>
                  )}
                  {tab === "triage_discharge" && (
                    <div className={styles.milestoneWrapper}>
                      {handleRenderProgressForm(tab)}
                    </div>
                  )}
                </div>
              );
            }}
          </TabMenu>
          <LaborFlowSheet
            title={"Triage Flow Sheet"}
            laborSheet={
              laborSheet?.[STAGES.TRIAGE] && laborSheet[STAGES.TRIAGE]
            }
            onDelete={(event, type, stage) =>
              handleDeleteClick(event, type, stage)
            }
            open
            encounter={encounter}
          />
          <LaborFlowSheet
            title={"Labor Flow Sheet"}
            // Filter out the triage entries since they're in the triage flow sheet
            laborSheet={
              laborSheet &&
              Object.fromEntries(
                Object.entries(laborSheet).filter(([k]) => k != STAGES.TRIAGE)
              )
            }
            onDelete={(event, type, stage) =>
              handleDeleteClick(event, type, stage)
            }
            hasAlternateViews
            open
            encounter={encounter}
          />
        </div>
      </div>
    </div>
  );
}
